import React from 'react'

const SignaxLogoBlock5: React.FC<React.SVGProps<SVGSVGElement>> = props => {
  return (
    <svg
      width="179"
      height="200"
      viewBox="0 0 179 200"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect
        width="178.667"
        height="200"
        transform="translate(0.332031)"
        fill="#102538"
      />
      <g clipPath="url(#clip0_31028_99124)">
        <path
          d="M65.0197 54.2106C64.8329 55.5547 64.7528 56.9504 64.7528 58.372C64.7528 73.1565 75.1604 83.4178 95.8422 89.1559L101.633 90.655C104.168 91.3271 105.956 91.7406 106.997 92.1542C108.038 92.4385 109.372 92.8262 110.706 93.3948C113.535 94.4804 114.282 96.0054 114.282 97.763C114.282 101.046 110.866 102.7 104.168 102.7C95.095 102.7 88.8504 98.5901 85.4346 90.5258L60.0027 104.07C65.2065 118.854 80.8179 128.159 103.421 128.159C107.237 128.159 110.813 127.901 114.176 127.384L36.332 150L65.0197 54.2106Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M116.822 63.3769C113.974 56.5968 109.191 53.1152 102.447 53.1152C97.2074 53.1152 94.5204 55.3403 94.5204 58.2461C94.5204 60.3141 95.7295 61.8586 97.9597 62.9581C100.351 64.0576 104.838 65.445 111.582 67.2513C115.747 68.4031 117.628 68.8743 120.637 70L141.73 50H141.569L116.822 63.3769Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_31028_99124">
          <rect
            width="105.484"
            height="100"
            fill="white"
            transform="translate(36.332 50)"
          />
        </clipPath>
      </defs>
    </svg>
  )
}

export default SignaxLogoBlock5
