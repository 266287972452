import React from 'react'

const SignaxLogoBlock4: React.FC<React.SVGProps<SVGSVGElement>> = props => {
  return (
    <svg
      width="178.667"
      height="200"
      viewBox="0 0 183 202"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <mask id="path-1-inside-1_31028_99117" fill="white">
        <path d="M1 0H179.667V200H1V0Z" />
      </mask>
      <path d="M1 0H179.667V200H1V0Z" fill="white" />
      <g filter="url(#filter0_di_31028_99117)">
        <rect
          x="33.7852"
          y="35"
          width="112.432"
          height="130"
          rx="7.02703"
          fill="url(#paint0_linear_31028_99117)"
        />
        <rect
          x="34.4879"
          y="35.7027"
          width="111.027"
          height="128.595"
          rx="6.32432"
          stroke="url(#paint1_linear_31028_99117)"
          strokeWidth="1.40541"
          fill="url(#paint1_linear_31028_99117)"
        />
        <path
          d="M104.216 67.8074C102.508 63.6091 99.6394 61.4532 95.5942 61.4532C92.4516 61.4532 90.84 62.8311 90.84 64.6303C90.84 65.9109 91.5652 66.8673 92.9028 67.5481C94.3372 68.2289 97.0286 69.088 101.074 70.2065C101.406 70.3014 101.714 70.3888 102.003 70.4707C103.884 71.0045 104.94 71.3042 106.505 71.9085L119.156 59.5243H119.059L104.216 67.8074Z"
          fill="white"
        />
        <path
          d="M73.0319 61.9397C72.9191 62.7826 72.8707 63.658 72.8707 64.5495C72.8707 73.8214 79.156 80.2567 91.6461 83.8552L95.1433 84.7954C95.5298 84.9018 95.8876 84.9979 96.2167 85.0862C97.1908 85.3478 97.9128 85.5417 98.3826 85.7356C99.0112 85.9139 99.817 86.157 100.623 86.5136C102.331 87.1944 102.782 88.1508 102.782 89.2531C102.782 91.3117 100.719 92.3491 96.6743 92.3491C91.1948 92.3491 87.4236 89.7718 85.3608 84.7144L70.0021 93.2082C73.1447 102.48 82.5727 108.316 96.2231 108.316C98.5277 108.316 100.687 108.154 102.718 107.829L55.707 122.013L73.0319 61.9397Z"
          fill="white"
        />
        <path
          d="M63.5072 128.254C62.8142 127.861 62.0245 127.575 61.0737 127.771C60.0745 127.982 59.3815 128.691 59.3815 129.506C59.3815 130.576 60.3289 131.041 61.4091 131.571C62.8411 132.274 64.5064 133.091 64.5064 135.585C64.5064 138.285 62.7336 140.427 59.6393 140.427C58.5918 140.427 57.5603 140.185 56.6901 139.718V137.123C57.4636 137.5 58.2533 137.802 59.2042 137.757C60.3646 137.712 61.2026 137.048 61.2026 136.022C61.2026 135.751 61.1542 135.524 61.0253 135.283C60.7622 134.829 60.0229 134.487 59.4622 134.227C59.3704 134.185 59.2835 134.145 59.2042 134.106C57.2864 133.231 56.0615 132.402 56.0615 130.426C56.0615 128.284 57.6087 126.021 60.5096 125.282C61.67 124.995 62.508 125.01 63.5072 125.267V128.254Z"
          fill="white"
        />
        <path
          d="M66.7304 140.425H70.1148V122.384L66.7304 123.403V140.425Z"
          fill="white"
        />
        <path
          d="M84.2003 129.851C84.2003 129.3 84.2003 128.749 84.168 128.214L78.7208 129.017V132.558L80.8642 132.338C80.832 134.007 80.6225 136.792 78.7852 136.871C76.3517 136.981 76.2711 132.401 76.2711 130.481C76.2711 128.655 76.4484 123.823 78.753 123.319C80.0262 123.036 80.3001 124.594 80.4613 125.759L83.7329 123.555C83.0238 120.722 81.3316 119.148 78.7208 119.841C73.9182 121.116 72.645 126.782 72.645 131.016C72.645 135.108 74.1115 140.475 78.7208 140.475C81.6217 140.475 83.33 137.705 83.894 134.4C84.1519 132.905 84.2003 131.362 84.2003 129.851Z"
          fill="white"
        />
        <path
          d="M94.1121 140.474L89.9056 126.232C90.0345 127.094 90.0829 128.004 90.0829 128.899V140.476H86.6985V117.435L89.8641 116.482L94.1119 130.655C93.9508 129.565 93.8541 128.46 93.8541 127.354V115.279L97.2868 114.245V140.474H94.1121Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M106.344 135.758L106.987 140.472H110.567L106.191 111.562L103.051 112.496L98.9469 140.475H102.33L102.944 135.92L106.344 135.758ZM105.748 130.918L105.749 130.922C105.775 131.118 105.802 131.314 105.829 131.511L103.459 131.722C103.943 127.88 104.426 124.006 104.588 120.115C104.637 121.012 104.715 121.936 104.792 122.853L104.792 122.853C104.816 123.131 104.839 123.407 104.862 123.682C105.1 126.125 105.421 128.498 105.748 130.918Z"
          fill="white"
        />
        <path
          d="M117.04 119.197L113.693 109.303L110.455 110.277L115.658 125.228L112.165 140.476H115.402L117.688 131.059L120.965 140.476H124.238L119.094 125.268L123.701 106.289L119.723 107.485L117.04 119.197Z"
          fill="white"
        />
      </g>
      <defs>
        <filter
          id="filter0_di_31028_99117"
          x="0.055426"
          y="1.27027"
          width="182.704"
          height="200.27"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="1.40541" dy="1.40541" />
          <feGaussianBlur stdDeviation="17.5676" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.0431373 0 0 0 0 0.596078 0 0 0 0 0.85098 0 0 0 0.3 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_31028_99117"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_31028_99117"
            result="shape"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="5.62162" dy="5.62162" />
          <feGaussianBlur stdDeviation="2.81081" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.158334 0 0 0 0 0.731643 0 0 0 0 1 0 0 0 1 0"
          />
          <feBlend
            mode="normal"
            in2="shape"
            result="effect2_innerShadow_31028_99117"
          />
        </filter>
        <linearGradient
          id="paint0_linear_31028_99117"
          x1="33.7852"
          y1="35"
          x2="153.936"
          y2="42.1776"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#0695D7" />
          <stop offset="1" stopColor="#3AB7F0" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_31028_99117"
          x1="33.7852"
          y1="35"
          x2="143.327"
          y2="29.2188"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#23A9E8" stopOpacity="0.99" />
          <stop offset="1" stopColor="#50B9EA" />
        </linearGradient>
      </defs>
    </svg>
  )
}

export default SignaxLogoBlock4
