import React from 'react'

const SignalLogoBlock4: React.FC<React.SVGProps<SVGSVGElement>> = props => {
  return (
    <svg
      width="178.667"
      height="200"
      viewBox="0 0 178.667 200"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g filter="url(#filter0_di_30267_18776)">
        <rect
          x="34"
          y="34"
          width="112.432"
          height="130"
          rx="7.02703"
          fill="url(#paint0_linear_30267_18776)"
        />
        <rect
          x="34.7027"
          y="34.7027"
          width="111.027"
          height="128.595"
          rx="6.32433"
          stroke="url(#paint1_linear_30267_18776)"
          strokeWidth="1.40541"
          fill="url(#paint1_linear_30267_18776)"
        />
        <g clipPath="url(#clip0_30267_18776)">
          <path
            d="M98.4136 60.5202C102.452 60.5202 105.315 62.6724 107.021 66.8634L121.838 58.5946H121.935L109.305 70.9573C107.743 70.3541 106.689 70.0549 104.811 69.5221L104.811 69.522C104.523 69.4402 104.215 69.353 103.884 69.2583C99.8455 68.1417 97.1588 67.2841 95.7269 66.6045C94.3916 65.9249 93.6676 64.9701 93.6676 63.6918C93.6676 61.8956 95.2765 60.5202 98.4136 60.5202Z"
            fill="white"
          />
          <path
            d="M75.7296 63.6108C75.7296 62.7208 75.7779 61.847 75.8905 61.0056L58.5957 120.974L105.525 106.816C103.498 107.139 101.342 107.301 99.0414 107.301C85.4147 107.301 76.0031 101.476 72.8659 92.2198L88.198 83.7407C90.2572 88.7893 94.0219 91.3622 99.4919 91.3622C103.53 91.3622 105.589 90.3266 105.589 88.2715C105.589 87.1712 105.139 86.2165 103.433 85.5369C102.629 85.1809 101.825 84.9381 101.197 84.7601C100.728 84.5666 100.007 84.3731 99.0351 84.112L99.0341 84.1117C98.7059 84.0236 98.349 83.9277 97.9635 83.8216L94.4723 82.8831C82.004 79.2908 75.7296 72.8667 75.7296 63.6108Z"
            fill="white"
          />
          <path
            d="M63.9531 126.723C64.9023 126.527 65.6907 126.813 66.3824 127.204V124.223C65.385 123.967 64.5484 123.952 63.39 124.238C60.4942 124.976 58.9497 127.235 58.9497 129.373C58.9497 131.346 60.1724 132.174 62.0869 133.047C62.1661 133.085 62.2529 133.125 62.3444 133.168C62.9042 133.427 63.6422 133.769 63.9049 134.222C64.0336 134.463 64.0818 134.688 64.0818 134.96C64.0818 135.984 63.2452 136.646 62.0869 136.691C61.1377 136.736 60.3494 136.435 59.5771 136.059V138.649C60.4459 139.116 61.4756 139.357 62.5213 139.357C65.6102 139.357 67.3799 137.218 67.3799 134.523C67.3799 132.034 65.7175 131.218 64.288 130.516C63.2097 129.987 62.2639 129.523 62.2639 128.454C62.2639 127.641 62.9557 126.933 63.9531 126.723Z"
            fill="white"
          />
          <path
            d="M72.9784 139.355H69.5999V122.363L72.9784 121.345V139.355Z"
            fill="white"
          />
          <path
            d="M87.0074 127.165C87.0396 127.699 87.0396 128.249 87.0396 128.799C87.0396 130.308 86.9913 131.848 86.7339 133.34C86.1708 136.64 84.4655 139.405 81.5696 139.405C76.9684 139.405 75.5044 134.047 75.5044 129.962C75.5044 125.735 76.7753 120.079 81.5696 118.806C84.1759 118.115 85.8651 119.686 86.573 122.514L83.3071 124.714C83.1462 123.551 82.8727 121.996 81.6018 122.279C79.3012 122.782 79.1242 127.605 79.1242 129.428C79.1242 131.345 79.2046 135.917 81.6339 135.807C83.468 135.728 83.6771 132.947 83.7093 131.282L81.5696 131.502V127.967L87.0074 127.165Z"
            fill="white"
          />
          <path
            d="M92.7347 125.187L96.934 139.404H100.103V113.22L96.6763 114.252V126.307C96.6763 127.41 96.7729 128.514 96.9337 129.602L92.6933 115.453L89.5332 116.405V139.405H92.9117V127.849C92.9117 126.956 92.8634 126.047 92.7347 125.187Z"
            fill="white"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M109.786 139.401L109.145 134.696L105.75 134.858L105.138 139.405H101.761L105.858 111.474L108.992 110.542L113.361 139.401H109.786ZM108.63 130.456C108.603 130.259 108.577 130.062 108.55 129.865C108.223 127.449 107.903 125.08 107.665 122.641C107.642 122.366 107.619 122.09 107.596 121.814C107.519 120.899 107.441 119.975 107.391 119.081C107.23 122.964 106.748 126.832 106.265 130.667L108.63 130.456Z"
            fill="white"
          />
          <path
            d="M115.018 139.405V108.724L118.38 107.716V133.725L121.92 133.531V139.405H115.018Z"
            fill="white"
          />
        </g>
      </g>
      <defs>
        <filter
          id="filter0_di_30267_18776"
          x="0.270262"
          y="0.270262"
          width="182.702"
          height="200.27"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="1.40541" dy="1.40541" />
          <feGaussianBlur stdDeviation="17.5676" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.0431373 0 0 0 0 0.596078 0 0 0 0 0.85098 0 0 0 0.3 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_30267_18776"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_30267_18776"
            result="shape"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="5.62162" dy="5.62162" />
          <feGaussianBlur stdDeviation="2.81081" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.158334 0 0 0 0 0.731643 0 0 0 0 1 0 0 0 1 0"
          />
          <feBlend
            mode="normal"
            in2="shape"
            result="effect2_innerShadow_30267_18776"
          />
        </filter>
        <linearGradient
          id="paint0_linear_30267_18776"
          x1="34"
          y1="34"
          x2="154.151"
          y2="41.1776"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#0695D7" />
          <stop offset="1" stopColor="#3AB7F0" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_30267_18776"
          x1="34"
          y1="34"
          x2="143.542"
          y2="28.2188"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#23A9E8" stopOpacity="0.99" />
          <stop offset="1" stopColor="#50B9EA" />
        </linearGradient>
        <clipPath id="clip0_30267_18776">
          <rect
            width="63.363"
            height="80.8108"
            fill="white"
            transform="translate(58.5957 58.5946)"
          />
        </clipPath>
      </defs>
    </svg>
  )
}

export default SignalLogoBlock4
