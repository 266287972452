import React from 'react'

const SignalLogoBlock3: React.FC<React.SVGProps<SVGSVGElement>> = props => {
  return (
    <svg
      width="178.667"
      height="200"
      viewBox="0 0 178.667 200"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <mask id="path-1-inside-1_30267_19039" fill="white">
        <path d="M0.333984 0H179.001V200H0.333984V0Z" />
      </mask>
      <path d="M0.333984 0H179.001V200H0.333984V0Z" fill="white" />
      <path
        d="M179.001 199H0.333984V201H179.001V199Z"
        fill="#DCDCDC"
        mask="url(#path-1-inside-1_30267_19039)"
      />
      <rect
        x="33.334"
        y="35"
        width="112.432"
        height="130"
        rx="7.02703"
        fill="#102538"
      />
      <g clipPath="url(#clip0_30267_19039)">
        <path
          d="M97.7476 61.5201C101.786 61.5201 104.649 63.6722 106.355 67.8633L121.172 59.5945H121.269L108.639 71.9572C107.077 71.354 106.023 71.0548 104.145 70.522L104.145 70.5219C103.857 70.4401 103.549 70.3528 103.218 70.2581C99.1795 69.1416 96.4927 68.284 95.0609 67.6044C93.7256 66.9247 93.0016 65.97 93.0016 64.6917C93.0016 62.8955 94.6104 61.5201 97.7476 61.5201Z"
          fill="white"
        />
        <path
          d="M75.0636 64.6107C75.0636 63.7207 75.1119 62.8469 75.2245 62.0055L57.9297 121.974L104.859 107.815C102.832 108.139 100.676 108.301 98.3754 108.301C84.7487 108.301 75.3371 102.476 72.1999 93.2197L87.5319 84.7406C89.5912 89.7892 93.3559 92.3621 98.8258 92.3621C102.864 92.3621 104.923 91.3265 104.923 89.2714C104.923 88.1711 104.473 87.2164 102.767 86.5367C101.963 86.1807 101.159 85.938 100.531 85.76C100.062 85.5665 99.3415 85.373 98.3691 85.1118L98.3681 85.1116C98.0399 85.0234 97.683 84.9276 97.2975 84.8215L93.8063 83.8829C81.338 80.2906 75.0636 73.8666 75.0636 64.6107Z"
          fill="white"
        />
        <path
          d="M63.2871 127.722C64.2363 127.527 65.0246 127.813 65.7164 128.204V125.223C64.719 124.967 63.8824 124.952 62.724 125.238C59.8282 125.976 58.2837 128.234 58.2837 130.373C58.2837 132.345 59.5064 133.174 61.4209 134.047C61.5001 134.085 61.5869 134.125 61.6784 134.168C62.2382 134.427 62.9762 134.769 63.2389 135.222C63.3676 135.463 63.4158 135.688 63.4158 135.959C63.4158 136.983 62.5792 137.646 61.4209 137.691C60.4717 137.736 59.6834 137.435 58.9111 137.059V139.649C59.7799 140.116 60.8095 140.357 61.8553 140.357C64.9442 140.357 66.7139 138.218 66.7139 135.523C66.7139 133.033 65.0515 132.218 63.622 131.516C62.5437 130.987 61.5979 130.523 61.5979 129.454C61.5979 128.641 62.2896 127.933 63.2871 127.722Z"
          fill="white"
        />
        <path
          d="M72.3123 140.355H68.9338V123.363L72.3123 122.345V140.355Z"
          fill="white"
        />
        <path
          d="M86.3414 128.165C86.3735 128.699 86.3735 129.249 86.3735 129.799C86.3735 131.307 86.3253 132.847 86.0679 134.34C85.5048 137.64 83.7994 140.405 80.9036 140.405C76.3024 140.405 74.8383 135.047 74.8383 130.962C74.8383 126.735 76.1093 121.079 80.9036 119.806C83.5099 119.115 85.1991 120.686 85.907 123.514L82.6411 125.714C82.4802 124.551 82.2067 122.996 80.9358 123.279C78.6351 123.781 78.4582 128.605 78.4582 130.428C78.4582 132.345 78.5386 136.917 80.9679 136.807C82.802 136.728 83.0111 133.947 83.0433 132.282L80.9036 132.502V128.966L86.3414 128.165Z"
          fill="white"
        />
        <path
          d="M92.0687 126.187L96.2679 140.403H99.4371V114.22L96.0103 115.252V127.307C96.0103 128.41 96.1068 129.514 96.2677 130.602L92.0273 116.453L88.8671 117.405V140.405H92.2457V128.849C92.2457 127.956 92.1974 127.047 92.0687 126.187Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M109.12 140.401L108.479 135.696L105.084 135.858L104.472 140.405H101.094L105.192 112.474L108.326 111.542L112.695 140.401H109.12ZM107.964 131.456C107.937 131.259 107.911 131.062 107.884 130.865C107.557 128.449 107.237 126.079 106.999 123.641C106.976 123.366 106.953 123.09 106.93 122.814C106.853 121.898 106.775 120.975 106.725 120.081C106.564 123.964 106.082 127.832 105.599 131.667L107.964 131.456Z"
          fill="white"
        />
        <path
          d="M114.352 140.405V109.723L117.714 108.716V134.725L121.254 134.531V140.405H114.352Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_30267_19039">
          <rect
            width="63.363"
            height="80.8108"
            fill="white"
            transform="translate(57.9297 59.5946)"
          />
        </clipPath>
      </defs>
    </svg>
  )
}

export default SignalLogoBlock3
