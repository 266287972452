import React from 'react'

const SignaxLogoBlock2: React.FC<React.SVGProps<SVGSVGElement>> = props => {
  return (
    <svg
      width="180"
      height="200"
      viewBox="0 0 180 200"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <mask id="path-1-inside-1_31028_99110" fill="white">
        <path d="M0.667969 0H179.335V200H0.667969V0Z" />
      </mask>
      <path d="M0.667969 0H179.335V200H0.667969V0Z" fill="white" />
      <path
        d="M179.335 200V201H180.335V200H179.335ZM178.335 0V200H180.335V0H178.335ZM179.335 199H0.667969V201H179.335V199Z"
        fill="#DCDCDC"
        mask="url(#path-1-inside-1_31028_99110)"
      />
      <g clipPath="url(#clip0_31028_99110)">
        <path
          d="M111.198 52.2787C108.656 46.0553 104.386 42.8594 98.3657 42.8594C93.6884 42.8594 91.2898 44.9019 91.2898 47.5691C91.2898 49.4673 92.3692 50.885 94.36 51.8943C96.4948 52.9035 100.501 54.177 106.521 55.835C107.016 55.9756 107.474 56.1052 107.904 56.2267C110.703 57.0179 112.275 57.4622 114.604 58.358L133.434 40H133.29L111.198 52.2787Z"
          fill="#102538"
        />
        <path
          d="M64.7853 43.5806C64.6174 44.8301 64.5455 46.1276 64.5455 47.4492C64.5455 61.1937 73.9001 70.7331 92.4896 76.0675L97.6946 77.4612C98.2699 77.6189 98.8024 77.7614 99.2922 77.8923C100.742 78.2801 101.817 78.5675 102.516 78.8549C103.451 79.1192 104.651 79.4796 105.85 80.0082C108.392 81.0175 109.064 82.4351 109.064 84.0691C109.064 87.1208 105.994 88.6586 99.9733 88.6586C91.8179 88.6586 86.2051 84.838 83.1349 77.341L60.2759 89.9321C64.9532 103.677 78.9852 112.327 99.3017 112.327C102.732 112.327 105.946 112.087 108.968 111.606L39 132.631L64.7853 43.5806Z"
          fill="#102538"
        />
        <path
          d="M50.6093 141.882C49.5779 141.301 48.4026 140.876 46.9874 141.167C45.5002 141.48 44.4688 142.531 44.4688 143.738C44.4688 145.325 45.879 146.014 47.4867 146.8C49.618 147.842 52.0965 149.053 52.0965 152.75C52.0965 156.752 49.458 159.928 44.8526 159.928C43.2935 159.928 41.7584 159.57 40.4631 158.877V155.031C41.6144 155.59 42.7898 156.037 44.205 155.97C45.932 155.903 47.1793 154.919 47.1793 153.398C47.1793 152.996 47.1073 152.66 46.9154 152.303C46.5238 151.63 45.4235 151.123 44.5889 150.738C44.4524 150.675 44.323 150.615 44.205 150.558C41.3506 149.261 39.5276 148.032 39.5276 145.102C39.5276 141.927 41.8303 138.573 46.1479 137.477C47.8749 137.052 49.1222 137.075 50.6093 137.455V141.882Z"
          fill="#102538"
        />
        <path
          d="M55.4065 159.925H60.4436V133.181L55.4065 134.692V159.925Z"
          fill="#102538"
        />
        <path
          d="M81.4076 144.251C81.4076 143.434 81.4076 142.617 81.3597 141.824L73.2523 143.014V148.264L76.4425 147.937C76.3945 150.41 76.0827 154.54 73.3482 154.657C69.7263 154.82 69.6064 148.031 69.6064 145.184C69.6064 142.478 69.8702 135.315 73.3003 134.568C75.1952 134.148 75.6029 136.458 75.8428 138.184L80.712 134.918C79.6566 130.718 77.1381 128.385 73.2523 129.412C66.1044 131.302 64.2094 139.701 64.2094 145.977C64.2094 152.044 66.3922 160 73.2523 160C77.5698 160 80.1124 155.893 80.9519 150.994C81.3357 148.777 81.4076 146.49 81.4076 144.251Z"
          fill="#102538"
        />
        <path
          d="M96.1599 159.997L89.8991 138.886C90.091 140.163 90.163 141.513 90.163 142.839V160H85.1258V125.845L89.8373 124.432L96.1596 145.442C95.9197 143.827 95.7758 142.188 95.7758 140.549V122.649L100.885 121.116V159.997H96.1599Z"
          fill="#102538"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M114.365 153.006L115.322 159.994H120.651L114.138 117.139L109.464 118.524L103.356 159.999H108.391L109.304 153.247L114.365 153.006ZM113.479 145.832L113.479 145.838C113.519 146.129 113.558 146.42 113.598 146.711L110.072 147.023C110.791 141.328 111.511 135.586 111.751 129.819C111.825 131.147 111.941 132.518 112.056 133.877L112.056 133.877C112.091 134.288 112.125 134.698 112.159 135.105C112.514 138.727 112.991 142.245 113.479 145.832Z"
          fill="#102538"
        />
        <path
          d="M130.284 128.457L125.303 113.791L120.484 115.234L128.228 137.397L123.029 160H127.846L131.248 146.041L136.126 160H140.998L133.341 137.456L140.198 109.323L134.277 111.095L130.284 128.457Z"
          fill="#102538"
        />
      </g>
      <defs>
        <clipPath id="clip0_31028_99110">
          <rect
            width="102"
            height="120"
            fill="white"
            transform="translate(39 40)"
          />
        </clipPath>
      </defs>
    </svg>
  )
}

export default SignaxLogoBlock2
