import React from 'react'

const SignaxLogoBlock1: React.FC<React.SVGProps<SVGSVGElement>> = props => {
  return (
    <svg
      width="179"
      height="200"
      viewBox="0 0 179 200"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect width="178.667" height="200" fill="#102538" />
      <g clipPath="url(#clip0_31028_99107)">
        <path
          d="M110.198 52.2787C107.656 46.0553 103.386 42.8594 97.3657 42.8594C92.6884 42.8594 90.2898 44.9019 90.2898 47.5691C90.2898 49.4673 91.3692 50.885 93.36 51.8943C95.4948 52.9035 99.5005 54.177 105.521 55.835C106.016 55.9756 106.474 56.1052 106.904 56.2267C109.703 57.0179 111.275 57.4622 113.604 58.358L132.434 40H132.29L110.198 52.2787Z"
          fill="white"
        />
        <path
          d="M63.7853 43.5806C63.6174 44.8301 63.5455 46.1276 63.5455 47.4492C63.5455 61.1937 72.9001 70.7331 91.4896 76.0675L96.6946 77.4612C97.2699 77.6189 97.8024 77.7614 98.2922 77.8923C99.742 78.2801 100.817 78.5675 101.516 78.8549C102.451 79.1192 103.651 79.4796 104.85 80.0082C107.392 81.0175 108.064 82.4351 108.064 84.0691C108.064 87.1208 104.994 88.6586 98.9733 88.6586C90.8179 88.6586 85.2051 84.838 82.1349 77.341L59.2759 89.9321C63.9532 103.677 77.9852 112.327 98.3017 112.327C101.732 112.327 104.946 112.087 107.968 111.606L38 132.631L63.7853 43.5806Z"
          fill="white"
        />
        <path
          d="M49.6093 141.882C48.5779 141.301 47.4026 140.876 45.9874 141.167C44.5002 141.48 43.4688 142.531 43.4688 143.738C43.4688 145.325 44.879 146.014 46.4867 146.8C48.618 147.842 51.0965 149.053 51.0965 152.75C51.0965 156.752 48.458 159.928 43.8526 159.928C42.2935 159.928 40.7584 159.57 39.4631 158.877V155.031C40.6144 155.59 41.7898 156.037 43.205 155.97C44.932 155.903 46.1793 154.919 46.1793 153.398C46.1793 152.996 46.1073 152.66 45.9154 152.303C45.5238 151.63 44.4235 151.123 43.5889 150.738C43.4524 150.675 43.323 150.615 43.205 150.558C40.3506 149.261 38.5276 148.032 38.5276 145.102C38.5276 141.927 40.8303 138.573 45.1479 137.477C46.8749 137.052 48.1222 137.075 49.6093 137.455V141.882Z"
          fill="white"
        />
        <path
          d="M54.4065 159.925H59.4436V133.181L54.4065 134.692V159.925Z"
          fill="white"
        />
        <path
          d="M80.4076 144.251C80.4076 143.434 80.4076 142.617 80.3597 141.824L72.2523 143.014V148.264L75.4425 147.937C75.3945 150.41 75.0827 154.54 72.3482 154.657C68.7263 154.82 68.6064 148.031 68.6064 145.184C68.6064 142.478 68.8702 135.315 72.3003 134.568C74.1952 134.148 74.6029 136.458 74.8428 138.184L79.712 134.918C78.6566 130.718 76.1381 128.385 72.2523 129.412C65.1044 131.302 63.2094 139.701 63.2094 145.977C63.2094 152.044 65.3922 160 72.2523 160C76.5698 160 79.1124 155.893 79.9519 150.994C80.3357 148.777 80.4076 146.49 80.4076 144.251Z"
          fill="white"
        />
        <path
          d="M95.1599 159.997L88.8991 138.886C89.091 140.163 89.163 141.513 89.163 142.839V160H84.1258V125.845L88.8373 124.432L95.1596 145.442C94.9197 143.827 94.7758 142.188 94.7758 140.549V122.649L99.8849 121.116V159.997H95.1599Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M113.365 153.006L114.322 159.994H119.651L113.138 117.139L108.464 118.524L102.356 159.999H107.391L108.304 153.247L113.365 153.006ZM112.479 145.832L112.479 145.838C112.519 146.129 112.558 146.42 112.598 146.711L109.072 147.023C109.791 141.328 110.511 135.586 110.751 129.819C110.825 131.147 110.941 132.518 111.056 133.877L111.056 133.877C111.091 134.288 111.125 134.698 111.159 135.105C111.514 138.727 111.991 142.245 112.479 145.832Z"
          fill="white"
        />
        <path
          d="M129.284 128.457L124.303 113.791L119.484 115.234L127.228 137.397L122.029 160H126.846L130.248 146.041L135.126 160H139.998L132.341 137.456L139.198 109.323L133.277 111.095L129.284 128.457Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_31028_99107">
          <rect
            width="102"
            height="120"
            fill="white"
            transform="translate(38 40)"
          />
        </clipPath>
      </defs>
    </svg>
  )
}

export default SignaxLogoBlock1
