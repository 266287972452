import React from 'react'

const SignaxLogoBlock3: React.FC<React.SVGProps<SVGSVGElement>> = props => {
  return (
    <svg
      width="179"
      height="200"
      viewBox="0 0 179 200"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <mask id="path-1-inside-1_31028_99113" fill="white">
        <path d="M0.332031 0H178.999V200H0.332031V0Z" />
      </mask>
      <path d="M0.332031 0H178.999V200H0.332031V0Z" fill="white" />
      <path
        d="M178.999 199H0.332031V201H178.999V199Z"
        fill="#DCDCDC"
        mask="url(#path-1-inside-1_31028_99113)"
      />
      <rect x="34" y="35" width="112" height="130" rx="7.03" fill="#102538" />
      <path
        d="M104.163 67.8074C102.461 63.6091 99.6032 61.4532 95.5736 61.4532C92.4431 61.4532 90.8376 62.8311 90.8376 64.6303C90.8376 65.9109 91.5601 66.8673 92.8926 67.5481C94.3214 68.2289 97.0024 69.088 101.032 70.2065C101.363 70.3014 101.67 70.3888 101.958 70.4707C103.831 71.0045 104.883 71.3042 106.442 71.9085L119.045 59.5243H118.948L104.163 67.8074Z"
        fill="white"
      />
      <path
        d="M73.0981 61.9397C72.9857 62.7826 72.9375 63.658 72.9375 64.5495C72.9375 73.8214 79.1987 80.2567 91.6407 83.8552L95.1244 84.7954C95.5095 84.9018 95.8659 84.9979 96.1937 85.0862C97.1641 85.3478 97.8833 85.5417 98.3513 85.7356C98.9774 85.9139 99.7801 86.157 100.583 86.5136C102.285 87.1944 102.734 88.1508 102.734 89.2531C102.734 91.3117 100.679 92.3491 96.6496 92.3491C91.1911 92.3491 87.4345 89.7718 85.3795 84.7144L70.0799 93.2082C73.2105 102.48 82.6022 108.316 96.2 108.316C98.4958 108.316 100.647 108.154 102.67 107.829L55.8398 122.013L73.0981 61.9397Z"
        fill="white"
      />
      <path
        d="M63.61 128.254C62.9197 127.861 62.133 127.575 61.1858 127.771C60.1905 127.982 59.5001 128.691 59.5001 129.506C59.5001 130.576 60.444 131.041 61.52 131.571C62.9465 132.274 64.6054 133.091 64.6054 135.585C64.6054 138.285 62.8394 140.427 59.757 140.427C58.7135 140.427 57.686 140.185 56.8191 139.718V137.123C57.5897 137.5 58.3764 137.802 59.3235 137.757C60.4795 137.712 61.3143 137.048 61.3143 136.022C61.3143 135.751 61.2661 135.524 61.1377 135.283C60.8755 134.829 60.1391 134.487 59.5805 134.227C59.4892 134.185 59.4025 134.145 59.3235 134.106C57.4131 133.231 56.193 132.402 56.193 130.426C56.193 128.284 57.7342 126.021 60.6239 125.282C61.7798 124.995 62.6147 125.01 63.61 125.267V128.254Z"
        fill="white"
      />
      <path
        d="M66.8208 140.425H70.1922V122.384L66.8208 123.403V140.425Z"
        fill="white"
      />
      <path
        d="M84.2235 129.851C84.2235 129.3 84.2235 128.749 84.1914 128.214L78.7651 129.017V132.558L80.9003 132.338C80.8682 134.007 80.6595 136.792 78.8293 136.871C76.4051 136.981 76.3248 132.401 76.3248 130.481C76.3248 128.655 76.5014 123.823 78.7972 123.319C80.0654 123.036 80.3384 124.594 80.4989 125.759L83.7579 123.555C83.0515 120.722 81.3658 119.148 78.7651 119.841C73.9809 121.116 72.7126 126.782 72.7126 131.016C72.7126 135.108 74.1736 140.475 78.7651 140.475C81.6548 140.475 83.3566 137.705 83.9184 134.4C84.1753 132.905 84.2235 131.362 84.2235 129.851Z"
        fill="white"
      />
      <path
        d="M94.0972 140.474L89.9069 126.232C90.0353 127.094 90.0835 128.004 90.0835 128.899V140.476H86.7121V117.435L89.8655 116.482L94.097 130.655C93.9365 129.565 93.8402 128.46 93.8402 127.354V115.279L97.2597 114.245V140.474H94.0972Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M106.282 135.758L106.922 140.472H110.489L106.13 111.562L103.002 112.496L98.9134 140.475H102.284L102.895 135.92L106.282 135.758ZM105.689 130.918L105.689 130.922C105.716 131.118 105.742 131.314 105.769 131.511L103.409 131.722C103.89 127.88 104.372 124.006 104.532 120.115C104.582 121.012 104.659 121.936 104.736 122.853L104.736 122.853C104.76 123.131 104.783 123.407 104.805 123.682C105.043 126.125 105.363 128.498 105.689 130.918Z"
        fill="white"
      />
      <path
        d="M116.937 119.197L113.603 109.303L110.377 110.277L115.561 125.228L112.081 140.476H115.305L117.582 131.059L120.847 140.476H124.107L118.983 125.268L123.572 106.289L119.609 107.485L116.937 119.197Z"
        fill="white"
      />
    </svg>
  )
}

export default SignaxLogoBlock3
