import React from 'react'
import Typography from '../Common/Typography'
import { useTranslations } from '../../hooks/use-translations'

import * as s from './ColorPalette.module.scss'
import { AppRegionEnum } from '../../types/app-region.enum'

interface IColorPalette {
  title: string
  subTitle?: string
  color: string
  size: number
  borderColor?: string
}

const colorPalette: Record<number, IColorPalette[]> = {
  0: [
    {
      title: 'Gradient',
      subTitle: '#2D4E67 – #112538',
      color: 'linear-gradient(248.59deg, #2D4E67 2%, #112538 98%)',
      size: 60,
    },
  ],
  1: [
    {
      title: 'dark',
      subTitle: '#102538',
      color: '#102538',
      size: 30,
    },
    {
      title: 'Blue accent',
      subTitle: '#0695D7',
      color: '#0695D7',
      size: 30,
    },
    {
      title: 'Pure white',
      subTitle: '#FFFFFF',
      color: '#FFFFFF',
      borderColor: '#ECF0F3',
      size: 30,
    },
    {
      title: 'Brand gray',
      subTitle: '#ECF0F3',
      color: '#ECF0F3',
      size: 30,
    },
  ],
  2: [
    {
      title: 'DOCS Brand',
      subTitle: '#00D1FF–0695D7',
      color:
        'linear-gradient(135deg, #00D1FF 0%, #0695D7 100%), linear-gradient(0deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.1)),  linear-gradient(0deg, rgba(135, 195, 250, 0.32), rgba(135, 195, 250, 0.32))',
      size: 30,
    },
    {
      title: 'DASHBOARD Brand',
      subTitle: '#A09EFF-4B47FF',
      color:
        'linear-gradient(135deg, #A09EFF 0%, #4B47FF 100%), linear-gradient(0deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.1)), linear-gradient(0deg, rgba(135, 195, 250, 0.32), rgba(135, 195, 250, 0.32))',
      size: 30,
    },
    {
      title: 'INSPECTION Brand',
      subTitle: '#83E6C7-00CD8D',
      color:
        'linear-gradient(135deg, #83E6C7 0%, #00CD8D 100%), linear-gradient(0deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.1)), linear-gradient(0deg, rgba(135, 195, 250, 0.32), rgba(135, 195, 250, 0.32))',
      size: 30,
    },
    {
      title: 'TOOLS Brand',
      subTitle: '#7D96AD-7AA6CF',
      color:
        'linear-gradient(180deg, rgba(255, 255, 255, 0.1575) 0%, rgba(255, 255, 255, 0.35) 100%), linear-gradient(180deg, #7D96AD 0%, #7AA6CF 100%)',
      size: 30,
    },
  ],
  3: [
    {
      title: 'BUILD Brand',
      subTitle: '#FFBB56-FF9900',
      color:
        'linear-gradient(135deg, #FFBB56 0%, #FF9900 100%), linear-gradient(0deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.1))',
      size: 30,
    },
    {
      title: 'Pure black',
      subTitle: '#000000',
      color: '#000000',
      size: 30,
    },
    {
      title: 'Dark gray',
      subTitle: '#5B5B5B',
      color: '#5B5B5B',
      size: 30,
    },
    {
      title: 'Deep gray',
      subTitle: '8F98A1',
      color: '#8F98A1',
      size: 30,
    },
  ],
  4: [
    {
      title: 'Medium gray',
      subTitle: '#B6BEC7',
      color: '#B6BEC7',
      size: 30,
    },
    {
      title: 'Gray main',
      subTitle: '#DCDCDC',
      color: '#DCDCDC',
      size: 30,
    },
    {
      title: 'Accent red',
      subTitle: '#FF4758',
      color: '#FF4758',
      size: 30,
    },
    {
      title: 'Accent deep\n blue',
      subTitle: '#0675D7',
      color: '#0675D7',
      size: 30,
    },
  ],
  5: [
    {
      title: 'Accent light\n blue',
      subTitle: '#8EDDFF',
      color: '#8EDDFF',
      size: 30,
    },
    {
      title: 'Accent violet',
      subTitle: '#8D90FF',
      color: '#8D90FF',
      size: 30,
    },
    {
      title: 'Accent yellow',
      subTitle: '#F2C94C',
      color: '#F2C94C',
      size: 30,
    },
    {
      title: 'Accent green',
      subTitle: '#4FCDA6',
      color: '#4FCDA6',
      size: 30,
    },
  ],
}

interface ColorPaletteProps {
  region: AppRegionEnum
}

const ColorPalette = ({ region }: ColorPaletteProps) => {
  const { t } = useTranslations()

  return (
    <>
      <div style={{ marginBottom: 24 }}>
        {renderColors(
          colorPalette[0].map(item => ({
            ...item,
            title: `${t('Signal')} ${item.title}`,
          }))
        )}
      </div>

      <div className="flex flex-col" style={{ gap: 16 }}>
        {renderColors(
          colorPalette[1].map((item, index) =>
            index === 0
              ? { ...item, title: `${t('Signal')} ${item.title}` }
              : { ...item }
          )
        )}
        {renderColors(colorPalette[2])}
        {renderColors(colorPalette[3])}
        {renderColors(colorPalette[4])}
        {renderColors(colorPalette[5])}
      </div>
    </>
  )
}

export default ColorPalette

function renderColors(color: IColorPalette[]) {
  return (
    <div className={s.colors}>
      {color.map((item, idx) => (
        <div className={s.color} key={item.title + idx}>
          <div
            style={{
              width: item.size,
              height: item.size,
              borderRadius: '50%',
              border: item.borderColor
                ? `1px solid ${item.borderColor}`
                : undefined,

              background: item.color,

              marginBottom: 8,
            }}
          />
          <Typography variant="body2">{item.title}</Typography>
          {item.subTitle && (
            <Typography variant="body1" color="gray">
              {item.subTitle}
            </Typography>
          )}
        </div>
      ))}
    </div>
  )
}
