import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'

import SEO from '../../components/seo'
import BannerSection from '../../components/BannerSection'
import Section from '../../components/Common/Section'
import Container from '../../components/Common/Container'
import Typography from '../../components/Common/Typography'
import NavLinkButton from '../../components/Common/NavLinkButton'
import Logotypes from '../../components/Logotypes'
import ColorPalette from '../../components/ColorPalette/iindex'

import SgnlImageSeo from '../../assets/images/seo/sgnl/brand.png'
import SgnxImageSeo from '../../assets/images/seo/sgnx/brand.png'
import { GlobalContext } from '../../context/GlobalContext'
import { AppRegionEnum } from '../../types/app-region.enum'
import { useTranslations } from '../../hooks/use-translations'

import * as s from './brand.module.scss'
import cn from 'classnames'

const Brand: React.FC = () => {
  const { t } = useTranslations()
  const images = useStaticQuery(imagesQuery)
  const { region } = React.useContext(GlobalContext)

  return (
    <>
      <SEO
        title={t('Brand')}
        description={t('Brand identity')}
        image={region === AppRegionEnum.Ru ? SgnlImageSeo : SgnxImageSeo}
      />
      <BannerSection
        imageDesktop={images.banner.childImageSharp.gatsbyImageData}
        imageMobile={images.bannerMobile.childImageSharp.gatsbyImageData}
        title={t('Brand identity')}
      />
      <Section>
        <Container>
          <div className={s.columns}>
            <div className={s.column}>
              <Typography
                variant="h2"
                color="blue"
                size={40}
                className={s.title}
              >
                {t('Logotype')}
              </Typography>
              <Typography variant="body1" size={18} mb={32}>
                {region === AppRegionEnum.Ru && `${t('Logotype')} `}
                <Typography variant="body2" color="blue">
                  {t('Signal')}
                </Typography>
                {t('Logo description')}
              </Typography>
            </div>
            <div className={cn(s.download_links, s.column)}>
              <div>
                <NavLinkButton
                  to={`/zip/Logo_${t('Signal')}_png.zip`}
                  title={`${t('Download')} .png`}
                  download
                />
              </div>
              <div>
                <NavLinkButton
                  to={`/zip/Logo_${t('Signal')}_svg.zip`}
                  title={`${t('Download')} .svg`}
                  download
                />
              </div>
              <div>
                <NavLinkButton
                  to={`/zip/Logo_${t('Signal')}_eps.zip`}
                  title={`${t('Download')} .eps`}
                  download
                />
              </div>
              <div>
                <NavLinkButton
                  to={`/zip/Logo_${t('Signal')}_all.zip`}
                  title={t('Download all formats')}
                  download
                />
              </div>
            </div>
            <div className="flex">
              <Logotypes region={region as AppRegionEnum} />
            </div>
          </div>
        </Container>
      </Section>
      <Section>
        <Container>
          <div className={cn(s.columns, s.columns_bottom)}>
            <div className={s.column}>
              <Typography
                variant="h2"
                color="blue"
                size={40}
                className={s.title}
              >
                {t('Color palette')}
              </Typography>
              <Typography variant="body1" size={18} mb={32}>
                {t('Color palette description')}
              </Typography>
            </div>
            <div>
              <ColorPalette region={region as AppRegionEnum} />
            </div>
          </div>
        </Container>
      </Section>
    </>
  )
}

export default Brand

const imagesQuery = graphql`
  query {
    banner: file(relativePath: { eq: "building-banner.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
      }
    }
    bannerMobile: file(relativePath: { eq: "building-mobile-banner.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
      }
    }
  }
`
