import React from 'react'
import cn from 'classnames'

import { AppRegionEnum } from '../../types/app-region.enum'
import SignalLogoBlock1 from '../../svg/signal-logo-block-1'
import SignalLogoBlock2 from '../../svg/signal-logo-block-2'
import SignalLogoBlock3 from '../../svg/signal-logo-block-3'
import SignalLogoBlock4 from '../../svg/signal-logo-block-4'
import SignalLogoBlock5 from '../../svg/signal-logo-block-5'
import SignalLogoBlock6 from '../../svg/signal-logo-block-6'
import SignaxLogoBlock1 from '../../svg/signax-logo-block-1'
import SignaxLogoBlock2 from '../../svg/signax-logo-block-2'
import SignaxLogoBlock3 from '../../svg/signax-logo-block-3'
import SignaxLogoBlock4 from '../../svg/signax-logo-block-4'
import SignaxLogoBlock5 from '../../svg/signax-logo-block-5'
import SignaxLogoBlock6 from '../../svg/signax-logo-block-6'

import * as s from './Logotypes.module.scss'

interface LogotypesProps {
  region: AppRegionEnum
}

const Logotypes = ({ region }: LogotypesProps) => {
  switch (region) {
    case AppRegionEnum.Ru:
      return (
        <div className={cn('inline-flex flex-wrap', s.logo_container)}>
          <SignalLogoBlock1 className={s.logo} />
          <SignalLogoBlock2 className={s.logo} />
          <SignalLogoBlock3 className={s.logo} />
          <SignalLogoBlock4
            className={s.logo}
            style={{ borderRight: '1px solid #DCDCDC' }}
          />
          <SignalLogoBlock5 className={s.logo} />
          <SignalLogoBlock6 className={s.logo} />
        </div>
      )

    case AppRegionEnum.Us:
      return (
        <div className={cn('inline-flex flex-wrap', s.logo_container)}>
          <SignaxLogoBlock1 className={s.logo} />
          <SignaxLogoBlock2 className={s.logo} />
          <SignaxLogoBlock3 className={s.logo} />
          <SignaxLogoBlock4
            className={s.logo}
            style={{ borderRight: '1px solid #DCDCDC' }}
          />
          <SignaxLogoBlock5 className={s.logo} />
          <SignaxLogoBlock6 className={s.logo} />
        </div>
      )
  }
}

export default Logotypes
