import React from 'react'

const SignalLogoBlock2: React.FC<React.SVGProps<SVGSVGElement>> = props => {
  return (
    <svg
      width="178.667"
      height="200"
      viewBox="0 0 178.667 200"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <mask id="path-1-inside-1_30267_19037" fill="white">
        <path d="M0.666016 0H179.333V200H0.666016V0Z" />
      </mask>
      <path d="M0.666016 0H179.333V200H0.666016V0Z" fill="white" />
      <path
        d="M179.333 200V201H180.333V200H179.333ZM178.333 0V200H180.333V0H178.333ZM179.333 199H0.666016V201H179.333V199Z"
        fill="#DCDCDC"
        mask="url(#path-1-inside-1_30267_19037)"
      />
      <g clipPath="url(#clip0_30267_19037)">
        <path
          d="M101.794 42.8594C107.79 42.8594 112.043 46.0553 114.575 52.2787L136.578 40H136.721L117.967 58.358C115.648 57.4623 114.082 57.018 111.294 56.2268L111.293 56.2266C110.865 56.1052 110.409 55.9756 109.916 55.835C103.92 54.177 99.9302 52.9035 97.804 51.8943C95.8211 50.8851 94.7461 49.4674 94.7461 47.5691C94.7461 44.9019 97.1351 42.8594 101.794 42.8594Z"
          fill="#102538"
        />
        <path
          d="M68.109 47.4489C68.109 46.1273 68.1807 44.8297 68.3479 43.5802L42.666 132.631L112.354 111.606C109.343 112.086 106.142 112.327 102.726 112.327C82.4909 112.327 68.5151 103.676 63.8566 89.9318L86.6239 77.3407C89.6818 84.8377 95.2721 88.6583 103.395 88.6583C109.391 88.6583 112.449 87.1204 112.449 84.0687C112.449 82.4348 111.78 81.0171 109.248 80.0079C108.053 79.4793 106.859 79.1188 105.927 78.8545C105.231 78.5671 104.16 78.2797 102.716 77.892L102.715 77.8916C102.228 77.7607 101.698 77.6184 101.125 77.4608L95.941 76.0672C77.4262 70.7328 68.109 61.1933 68.109 47.4489Z"
          fill="#102538"
        />
        <path
          d="M50.6215 141.167C52.031 140.876 53.2017 141.301 54.2289 141.882V137.455C52.7478 137.075 51.5055 137.052 49.7854 137.477C45.4851 138.573 43.1917 141.927 43.1917 145.102C43.1917 148.031 45.0073 149.261 47.8503 150.558C47.9678 150.615 48.0967 150.675 48.2327 150.738C49.0639 151.122 50.1598 151.63 50.5499 152.302C50.741 152.66 50.8127 152.996 50.8127 153.398C50.8127 154.919 49.5704 155.903 47.8503 155.97C46.4408 156.037 45.2701 155.59 44.1234 155.03V158.877C45.4135 159.57 46.9424 159.928 48.4953 159.928C53.0822 159.928 55.7101 156.752 55.7101 152.75C55.7101 149.053 53.2416 147.842 51.1188 146.8C49.5176 146.014 48.1131 145.325 48.1131 143.738C48.1131 142.531 49.1403 141.48 50.6215 141.167Z"
          fill="#102538"
        />
        <path
          d="M64.0235 159.925H59.0066V134.692L64.0235 133.181V159.925Z"
          fill="#102538"
        />
        <path
          d="M84.8559 141.824C84.9037 142.617 84.9037 143.434 84.9037 144.25C84.9037 146.49 84.8321 148.777 84.4498 150.993C83.6137 155.893 81.0813 159.999 76.7811 159.999C69.9485 159.999 67.7745 152.043 67.7745 145.977C67.7745 139.701 69.6618 131.301 76.7811 129.411C80.6513 128.385 83.1597 130.718 84.2109 134.918L79.3612 138.184C79.1223 136.458 78.7162 134.148 76.8289 134.568C73.4126 135.314 73.1498 142.477 73.1498 145.184C73.1498 148.03 73.2692 154.82 76.8766 154.656C79.6001 154.54 79.9107 150.41 79.9585 147.937L76.7811 148.264V143.014L84.8559 141.824Z"
          fill="#102538"
        />
        <path
          d="M93.3607 138.886L99.5964 159.997H104.302V121.116L99.2138 122.649V140.549C99.2138 142.188 99.3572 143.827 99.5961 145.442L93.2992 124.432L88.6066 125.845V160H93.6235V142.839C93.6235 141.513 93.5519 140.163 93.3607 138.886Z"
          fill="#102538"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M118.682 159.994L117.729 153.007L112.688 153.247L111.779 159.999H106.764L112.847 118.524L117.503 117.139L123.989 159.994H118.682ZM116.965 146.711C116.925 146.418 116.885 146.125 116.846 145.833C116.361 142.246 115.885 138.727 115.531 135.105C115.498 134.698 115.463 134.288 115.429 133.877C115.314 132.518 115.199 131.147 115.125 129.819C114.886 135.586 114.17 141.329 113.453 147.024L116.965 146.711Z"
          fill="#102538"
        />
        <path
          d="M126.45 159.999V114.439L131.443 112.943V151.565L136.699 151.277V159.999H126.45Z"
          fill="#102538"
        />
      </g>
      <defs>
        <clipPath id="clip0_30267_19037">
          <rect
            width="94.0909"
            height="120"
            fill="white"
            transform="translate(42.666 40)"
          />
        </clipPath>
      </defs>
    </svg>
  )
}

export default SignalLogoBlock2
