import React from 'react'

const SignalLogoBlock6: React.FC<React.SVGProps<SVGSVGElement>> = props => {
  return (
    <svg
      width="178.667"
      height="200"
      viewBox="0 0 178.667 200"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect
        width="178.667"
        height="200"
        transform="translate(0.333984)"
        fill="#102538"
      />
      <g clipPath="url(#clip0_30269_20273)">
        <path
          d="M65.0216 54.2106C64.8348 55.5547 64.7547 56.9504 64.7547 58.372C64.7547 73.1565 75.1624 83.4178 95.8441 89.1559L101.635 90.655C104.17 91.3271 105.958 91.7406 106.999 92.1542C108.04 92.4385 109.374 92.8262 110.708 93.3948C113.537 94.4804 114.284 96.0054 114.284 97.763C114.284 101.046 110.868 102.7 104.17 102.7C95.0969 102.7 88.8524 98.5901 85.4365 90.5258L60.0046 104.07C65.2084 118.854 80.8198 128.159 103.423 128.159C107.239 128.159 110.815 127.901 114.178 127.384L36.334 150L65.0216 54.2106Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M116.824 63.3769C113.976 56.5968 109.193 53.1152 102.449 53.1152C97.2093 53.1152 94.5224 55.3403 94.5224 58.2461C94.5224 60.3141 95.7315 61.8586 97.9616 62.9581C100.353 64.0576 104.84 65.445 111.584 67.2513C115.749 68.4031 117.63 68.8743 120.639 70L141.732 50H141.571L116.824 63.3769Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_30269_20273">
          <rect
            width="105.484"
            height="100"
            fill="white"
            transform="translate(36.334 50)"
          />
        </clipPath>
      </defs>
    </svg>
  )
}

export default SignalLogoBlock6
