import React from 'react'

const SignaxLogoBlock5: React.FC<React.SVGProps<SVGSVGElement>> = props => {
  return (
    <svg
      width="180"
      height="200"
      viewBox="0 0 180 200"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect
        width="178.667"
        height="200"
        transform="translate(0.667969)"
        fill="white"
      />
      <g clipPath="url(#clip0_31028_99121)">
        <path
          d="M65.3556 54.2106C65.1688 55.5547 65.0887 56.9504 65.0887 58.372C65.0887 73.1565 75.4963 83.4178 96.1781 89.1559L101.969 90.655C104.504 91.3271 106.292 91.7406 107.333 92.1542C108.374 92.4385 109.708 92.8262 111.042 93.3948C113.871 94.4804 114.618 96.0054 114.618 97.763C114.618 101.046 111.202 102.7 104.504 102.7C95.4309 102.7 89.1863 98.5901 85.7705 90.5258L60.3386 104.07C65.5424 118.854 81.1538 128.159 103.757 128.159C107.573 128.159 111.149 127.901 114.512 127.384L36.668 150L65.3556 54.2106Z"
          fill="#102538"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M117.158 63.3769C114.31 56.5968 109.527 53.1152 102.783 53.1152C97.5433 53.1152 94.8564 55.3403 94.8564 58.2461C94.8564 60.3141 96.0655 61.8586 98.2956 62.9581C100.687 64.0576 105.174 65.445 111.918 67.2513C116.083 68.4031 117.964 68.8743 120.973 70L142.066 50H141.904L117.158 63.3769Z"
          fill="#102538"
        />
      </g>
      <defs>
        <clipPath id="clip0_31028_99121">
          <rect
            width="105.484"
            height="100"
            fill="white"
            transform="translate(36.668 50)"
          />
        </clipPath>
      </defs>
    </svg>
  )
}

export default SignaxLogoBlock5
