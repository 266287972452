import React from 'react'

const SignalLogoBlock1: React.FC<React.SVGProps<SVGSVGElement>> = props => {
  return (
    <svg
      width="178.667"
      height="200"
      viewBox="0 0 178.667 200"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect width="178.667" height="200" fill="#102538" />
      <g clipPath="url(#clip0_30267_19046)">
        <path
          d="M101.128 42.8594C107.124 42.8594 111.376 46.0553 113.909 52.2787L135.912 40H136.055L117.301 58.358C114.982 57.4623 113.416 57.018 110.628 56.2268L110.627 56.2266C110.199 56.1052 109.743 55.9756 109.25 55.835C103.254 54.177 99.2642 52.9035 97.138 51.8943C95.1551 50.8851 94.0801 49.4674 94.0801 47.5691C94.0801 44.9019 96.4691 42.8594 101.128 42.8594Z"
          fill="white"
        />
        <path
          d="M67.443 47.4489C67.443 46.1273 67.5147 44.8297 67.6819 43.5802L42 132.631L111.688 111.606C108.677 112.086 105.476 112.327 102.06 112.327C81.8249 112.327 67.8491 103.676 63.1905 89.9318L85.9579 77.3407C89.0158 84.8377 94.6061 88.6583 102.729 88.6583C108.725 88.6583 111.783 87.1204 111.783 84.0687C111.783 82.4348 111.114 81.0171 108.582 80.0079C107.387 79.4793 106.193 79.1188 105.261 78.8545C104.565 78.5671 103.494 78.2797 102.05 77.892L102.049 77.8916C101.562 77.7607 101.032 77.6184 100.459 77.4608L95.275 76.0672C76.7602 70.7328 67.443 61.1933 67.443 47.4489Z"
          fill="white"
        />
        <path
          d="M49.9555 141.167C51.365 140.876 52.5356 141.301 53.5629 141.882V137.455C52.0817 137.075 50.8394 137.052 49.1194 137.477C44.8191 138.573 42.5257 141.927 42.5257 145.102C42.5257 148.031 44.3413 149.261 47.1843 150.558C47.3018 150.615 47.4307 150.675 47.5667 150.738C48.3979 151.122 49.4937 151.63 49.8838 152.302C50.075 152.66 50.1466 152.996 50.1466 153.398C50.1466 154.919 48.9043 155.903 47.1843 155.97C45.7747 156.037 44.6041 155.59 43.4574 155.03V158.877C44.7475 159.57 46.2764 159.928 47.8293 159.928C52.4162 159.928 55.0441 156.752 55.0441 152.75C55.0441 149.053 52.5756 147.842 50.4528 146.8C48.8516 146.014 47.447 145.325 47.447 143.738C47.447 142.531 48.4743 141.48 49.9555 141.167Z"
          fill="white"
        />
        <path
          d="M63.3575 159.925H58.3406V134.692L63.3575 133.181V159.925Z"
          fill="white"
        />
        <path
          d="M84.1899 141.824C84.2377 142.617 84.2377 143.434 84.2377 144.25C84.2377 146.49 84.166 148.777 83.7838 150.993C82.9476 155.893 80.4153 159.999 76.1151 159.999C69.2825 159.999 67.1085 152.043 67.1085 145.977C67.1085 139.701 68.9958 131.301 76.1151 129.411C79.9853 128.385 82.4937 130.718 83.5449 134.918L78.6952 138.184C78.4563 136.458 78.0502 134.148 76.1629 134.568C72.7466 135.314 72.4838 142.477 72.4838 145.184C72.4838 148.03 72.6032 154.82 76.2106 154.656C78.9341 154.54 79.2447 150.41 79.2925 147.937L76.1151 148.264V143.014L84.1899 141.824Z"
          fill="white"
        />
        <path
          d="M92.6947 138.886L98.9304 159.997H103.636V121.116L98.5478 122.649V140.549C98.5478 142.188 98.6912 143.827 98.9301 145.442L92.6332 124.432L87.9406 125.845V160H92.9575V142.839C92.9575 141.513 92.8859 140.163 92.6947 138.886Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M118.016 159.994L117.063 153.007L112.022 153.247L111.113 159.999H106.098L112.181 118.524L116.837 117.139L123.323 159.994H118.016ZM116.299 146.711C116.259 146.418 116.219 146.125 116.18 145.833C115.695 142.246 115.219 138.727 114.865 135.105C114.832 134.698 114.797 134.288 114.763 133.877C114.648 132.518 114.533 131.147 114.459 129.819C114.22 135.586 113.504 141.329 112.787 147.024L116.299 146.711Z"
          fill="white"
        />
        <path
          d="M125.784 159.999V114.439L130.777 112.943V151.565L136.033 151.277V159.999H125.784Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_30267_19046">
          <rect
            width="94.0909"
            height="120"
            fill="white"
            transform="translate(42 40)"
          />
        </clipPath>
      </defs>
    </svg>
  )
}

export default SignalLogoBlock1
