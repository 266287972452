import React from 'react'

const SignalLogoBlock5: React.FC<React.SVGProps<SVGSVGElement>> = props => {
  return (
    <svg
      width="178.667"
      height="200"
      viewBox="0 0 178.667 200"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect
        width="178.667"
        height="200"
        transform="translate(0.666016)"
        fill="white"
      />
      <g clipPath="url(#clip0_30267_19041)">
        <path
          d="M65.3536 54.2106C65.1668 55.5547 65.0868 56.9504 65.0868 58.372C65.0868 73.1565 75.4944 83.4178 96.1762 89.1559L101.967 90.655C104.502 91.3271 106.29 91.7406 107.331 92.1542C108.372 92.4385 109.706 92.8262 111.04 93.3948C113.869 94.4804 114.616 96.0054 114.616 97.763C114.616 101.046 111.2 102.7 104.502 102.7C95.4289 102.7 89.1844 98.5901 85.7686 90.5258L60.3366 104.07C65.5404 118.854 81.1519 128.159 103.755 128.159C107.571 128.159 111.147 127.901 114.51 127.384L36.666 150L65.3536 54.2106Z"
          fill="#102538"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M117.156 63.3769C114.308 56.5968 109.525 53.1152 102.781 53.1152C97.5413 53.1152 94.8544 55.3403 94.8544 58.2461C94.8544 60.3141 96.0635 61.8586 98.2937 62.9581C100.685 64.0576 105.172 65.445 111.916 67.2513C116.081 68.4031 117.962 68.8743 120.971 70L142.064 50H141.903L117.156 63.3769Z"
          fill="#102538"
        />
      </g>
      <defs>
        <clipPath id="clip0_30267_19041">
          <rect
            width="105.484"
            height="100"
            fill="white"
            transform="translate(36.666 50)"
          />
        </clipPath>
      </defs>
    </svg>
  )
}

export default SignalLogoBlock5
